import requestHandler from '@/utils/requestHandler'
import HTTP from '@/utils/http'
import { getPaginationNumber } from '@/utils/pagination'

export default {
  namespaced: true,

  state: {
    integrationLogs: [],
    Page: {
      number: 1,
      size: 20,
      total: 0
    }
  },

  getters: {
    isEmpty: state => state.integrationLogs.length === 0,
    IntegrationLogs: state => state.integrationLogs,
    Page: state => ({
      ...state.Page,
      last: Math.ceil(state.Page.total / state.Page.size)
    })
  },

  actions: {
    async getIntegrationLogs({ commit, dispatch, state }, payload) {
      payload.pageNumber = getPaginationNumber({ selectedPage: payload.pageNumber, currentPage: state.Page.number })
      const loadingName = 'getIntegrationLogs'
      const results = await requestHandler({
        title: loadingName,
        methodName: loadingName,
        method: () => {
          return HTTP.post('Despatch/accountingintegrationlog/all', payload)
        },
        success: result => {
          commit('SET_INTEGRATION_LOG_LIST', result.data)
          return result
        }
      })
      return results
    }
  },

  mutations: {
    SET_INTEGRATION_LOG_LIST(state, data) {
      state.Page.number = data.pageNumber
      state.Page.size = data.pageSize
      state.Page.total = data.totalCount
      state.integrationLogs = data.accountingIntegrationLogList
    }
  }
}


