export function getFields($t) {
  return [
    {
      name: 'processName',
      type: 'portal',
      is_visible: true,
      get short_title() {
        return $t('IntegrationLogs.process')
      },
      get title() {
        return $t('IntegrationLogs.process')
      }
    },
    {
      name: 'integrationProductName',
      is_visible: true,
      get short_title() {
        return $t('IntegrationLogs.integrationProductName')
      },
      get title() {
        return $t('IntegrationLogs.integrationProductName')
      }
    },
    {
      name: 'errorMessage',
      is_visible: true,
      get short_title() {
        return $t('IntegrationLogs.errorMessage')
      },
      get title() {
        return $t('IntegrationLogs.errorMessage')
      }
    },
    {
      name: 'createdOn',
      type: 'date',
      is_visible: true,
      get short_title() {
        return $t('IntegrationLogs.createdOn')
      },
      get title() {
        return $t('IntegrationLogs.createdOn')

      }
    }
  ]
}

