import IntegrationLogs from '../index'
export default [
  {
    path: '/integration-logs',
    name: 'IntegrationLogs',
    component: IntegrationLogs,
    meta: {
      main_menu: true,
      slug: 'integrationLogs',
      page: 'integrationLogs',
      category: 'Governance',
      //tabPermissionKey: 'IntegrationLogMenu',
      permissionKey: 'IntegrationLogMenu'
    }
  }
]
